<template>
  <van-pull-refresh class="repairResponseRecord" v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad">

      <response-cell v-for="(item, index) in tableData" :data-info="item">
<!--        <div v-if="item.status==2||item.status==1" slot="footer">-->
<!--          <div class="actionButton" @click.stop="toAppointRole(item)">指派工程师</div>-->
<!--          <div style="clear: both"></div>-->
<!--        </div>-->
      </response-cell>

    </van-list>
  </van-pull-refresh>

</template>

<script>

import responseMixin from '@/views/shareAndDispatch/deviceView/response/responseMixin'
import ResponseCell from '@/views/shareAndDispatch/deviceView/response/responseCell/responseCell'
import repairMixin from '@views/shareAndDispatch/device/deviceRepair/reapirList/repairMixin'

export default {
  name: 'repairResponseRecord',
  components: { ResponseCell },
  mixins: [responseMixin, repairMixin],
  data() {
    return {
      queryType:6,
    }
  },
  methods: {
    toAppointRole(item) {
      this.$push("deviceRepair/appointRole", {
        id: item.id,
      });
    },
  }
}
</script>

<style lang="scss" scoped>

.repairResponseRecord {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

</style>